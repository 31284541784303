<template>
  <div>
    <el-form :inline="true" :model="searchForm" class="demo-form-inline">
      <el-form-item label="">
        <el-input
          clearable
          v-model="searchForm.taskName"
          placeholder="请输入任务名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-select
          clearable
          v-model="searchForm.taskStatus"
          placeholder="请选择状态"
        >
          <el-option
            v-for="(item, i) in statusList"
            :key="i"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="时间">
        <el-date-picker
          v-model="searchForm.dateList"
          type="datetimerange"
          range-separator="-"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          format="YYYY-MM-DD HH:mm:ss"
          value-format="YYYY-MM-DD HH:mm:ss"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="query">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      border
      max-height="460"
      :header-cell-style="{ background: '#E5F0FF' }"
      style="width: 100%"
    >
      <el-table-column type="index" label="序号" width="60" />

      <!-- <el-table-column prop="taskNum" label="任务编号" width="180" /> -->
      <el-table-column prop="taskName" label="任务名称" />
      <!-- <el-table-column prop="temId" label="短信模板ID" /> -->
      <el-table-column prop="temName" label="短信名称"/>
      <el-table-column prop="content" label="短信内容" min-width="300" />
      <el-table-column prop="words" label="短信内容长度" width="110" />
      <el-table-column prop="bar" label="单次计费条数" width="110" />
      <!-- <el-table-column prop="sumNum" label="发送条数" width="" />
      <el-table-column prop="successNum" label="成功条数" width="" />
      <el-table-column prop="failNum" label="失败条数" width="" /> -->
      <!-- <el-table-column prop="moneyNum" label="计费总条数" width="100" /> -->
      <el-table-column prop="taskStatus" label="状态" width="110">
        <template #default="scope">
          <el-tag :type="convurtType(scope.row.taskStatus)">
            <span>{{ convertTaskStatus(scope.row.taskStatus) }}</span>
          </el-tag>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="createUser" label="创建人" /> -->
      <el-table-column prop="createTime" label="创建时间" width="160" />
      <el-table-column label="操作" align="center" width="120">
        <template #default="scope">
          <el-button
            size="small"
            type="primary"
            @click="openinfoList(scope.row)"
            >任务详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      @size-change="sizeChangeHandler"
      @current-change="currentChangeHandler"
      :current-page="pagination.pageNo"
      :page-sizes="[10, 15, 20]"
      :page-size="pagination.length"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  name: "messageTaskList",
  data() {
    return {
      searchForm: {
        taskName: "",
        taskStatus: "",
        dateList: [],
        startTime: "",
        endTime: "",
      },
      querySubmitForm: {
        taskName: "",
        taskStatus: "",
        dateList: [],
        startTime: "",
        endTime: "",
      },
      statusList: [
        {
          label: "执行失败",
          value: 0,
        },
        {
          label: "启动",
          value: 1,
        },
        {
          label: "等待执行",
          value: 2,
        },
        {
          label: "执行完成",
          value: 3,
        },
      ],
      tableData: [],
      pagination: {
        total: 0, //总条数
        pageNo: 1, // 页数
        length: 15, // 每页的条数
      }, // 分页控件
    };
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    // 获取列表数据
    getTableData() {
      this.$http
        .request("getTaskList", {
          // 参数
          ...this.querySubmitForm,
          page: this.pagination.pageNo,
          limit: this.pagination.length,
        })
        .then((res) => {
          this.tableData = res.result.data;
          this.pagination.total = res.result.count;
        })
        .catch(() => {});
    },
    // 查询
    query() {
      if (this.searchForm.dateList) {
        this.searchForm.startTime = this.searchForm.dateList[0];
        this.searchForm.endTime = this.searchForm.dateList[1];
      } else {
        this.searchForm.startTime = "";
        this.searchForm.endTime = "";
      }

      this.querySubmitForm = Object.assign({}, this.searchForm);
      this.getTableData((this.pagination.pageNo = 1));
    },
    // 新建模板
    add() {
      const { href } = this.$router.resolve({
        name: "messageAdd",
        params: { id: 0 },
      });
      window.open(href, "_self");
    },
    // 任务详情
    openinfoList(row) {
      this.$router.push("messageTaskInfoList?taskId=" + row.id);
    },
    // 换每页条数
    sizeChangeHandler(length) {
      this.pagination.length = length;
      this.getTableData();
    },
    // 换页
    currentChangeHandler(pageNo) {
      this.pagination.pageNo = pageNo;
      this.getTableData();
    },
    convertTaskStatus(val) {
      if (val == 0) {
        return "执行失败";
      } else if (val == 1) {
        return "启动";
      } else if (val == 2) {
        return "等待执行";
      } else if (val == 3) {
        return "执行完成";
      }
    },
    convurtType(val) {
      if (val == 0) {
        return "danger";
      } else if (val == 1) {
        return "";
      } else if (val == 2) {
        return "warning";
      } else if (val == 3) {
        return "success";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-pagination {
  margin-top: 30px;
  text-align: right;
}
.ex_box {
  div {
    margin-bottom: 15px;
    line-height: 25px;
  }
}
</style>